function aosWatch (id, add, action) {
  if (id) {
    if (add == true && typeof(action) === "function") {
    document.addEventListener('aos:in:' + id, ({ detail }) => {
      var details = [];
      details.push('aos:in');
      details.push(Object.entries(detail));
      details.push(Object.entries(detail)[1][1]["data-aos-id"]);
      details.push(Object.entries(detail)[1][1]["data-aos"]);
      action();
      
    });
    } else if (add == true) {
      document.addEventListener('aos:in:' + id, ({ detail }) => {
        var details = [];
        details.push('aos:in');
        details.push(Object.entries(detail));
        details.push(Object.entries(detail)[1][1]["data-aos-id"]);
        details.push(Object.entries(detail)[1][1]["data-aos"]);
        
      });
      }
  } else {
    document.addEventListener('aos:in:', ({ detail }) => {
      var details = [];
      details.push('aos:in');
      details.push(Object.entries(detail));
      details.push(Object.entries(detail)[1][1]["data-aos-id"]);
      details.push(Object.entries(detail)[1][1]["data-aos"]);
    });
  }
  document.addEventListener('aos:out', ({ detail }) => {
        var details = [];
        details.push('aos:out');
        details.push(Object.entries(detail));
        details.push(Object.entries(detail)[1][1]["data-aos-id"]);
        details.push(Object.entries(detail)[1][1]["data-aos"]);
  });
}

export default aosWatch;